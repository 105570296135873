import './NavbarSmall.css'
import BSoftButton from  "../smallComponents/BSoftButton"
import BSoftButton1 from  "../smallComponents/BSoftButton1"
import BSoftTextInput from  "../smallComponents/bstyleInput"
import { useState,useEffect, useRef, useId, useContext } from "react"
import { useNavigate } from 'react-router-dom';




function NavbarDefaultContent(){           
    const navigate = useNavigate();
    const menuButtonToogleRef = useRef(null);
    const navbarMenuContainerRef = useRef(null);
    const navbarSeparatorLineRef = useRef(null);

    function responsitiveControler(){
        
    }

    function hideOrShowNavbarMenuContainer(){
        responsitiveControler();
        if (navbarMenuContainerRef.current.style.display !== "none") {
            navbarMenuContainerRef.current.style.display= "none";
            navbarSeparatorLineRef.current.style.display= "none";

        } else {
            navbarMenuContainerRef.current.style.display= "block";
            navbarSeparatorLineRef.current.style.display= "block";

        }
    }

   

    return(
        <>
             

            <div className='navbarsmallontainer'>
                
                <div className='navbarSmallTopPart'>
                    <div className='navbarLeftContainer'>
                        <div className="menuTogleButtonAndLogoContainer" href="#">

                             <div className='menuButtonToogle' ref={menuButtonToogleRef} onClick={hideOrShowNavbarMenuContainer}>
                                 <i class="fa-solid fa-bars"></i>
                             </div>           
                            <div className='logoCharacter' >
                                <img src="/img/BKG_Tech_LOGO.png" alt=".." height="45"/>
                            </div>
                        </div>
                    </div>
                   
                    <div className='navbarSmallRightContainer'>

                    </div>

                </div>

                <hr className='navbarSmallSeparatorLine' ref={navbarSeparatorLineRef}></hr>

                < div className="navbarSmallBottomPart">

                            <ul className="navbarSmallMenuContainer" ref={navbarMenuContainerRef}>
                                <li className="navSmallMenuItem">
                                    <a className="nav-link" href="#">Home</a>
                                </li>
                                <li className='navSmallMenuItem'>
                                    <a className="nav-link" href="#ourServices">Our services</a>
                                </li>
                                <li className="navSmallMenuItem">
                                   <a className="nav-link" href="#aboutUs">About us</a>
                                </li>
                                <li className="navSmallMenuItem">
                                    <a className="nav-link" href="#contactUs">
                                        <BSoftButton1 className="navbarButtonLoginin"  style={{maxHeight:50,maxWidth:320, height:'100%', width:'100%', 'background-color':'var(--primary-color)', display:'flex', 'justify-content':'center', 'align-items':'center', color:'white'}} label="Contact us" />
                                    </a>     
                                </li>
   
                                
                                
                            </ul>    
                </div>    



                
            </div>
           

        </>

           



    );
}

export default NavbarDefaultContent;