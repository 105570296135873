import Button from 'react-bootstrap/esm/Button';
import './Footer.css'

function  FooterDefaultFunct(){
    return(
        <>
        
            <section className="footer">

                <div className="footerContainer">
                    <div className='footerSubContainer0'>
                        <div className='footerSubContainer1'>
                            <div className="footerSubContainer2">
                                
                                <div className="footerColum footerColum0">
                                    <div className="footerColumTitle ">
                                        <div className='logo'>
                                            <img src="/img/BKG_Tech_LOGO.png" height="45" width="280"> 
                                            </img>
                                        </div>
                                    </div>
                                    <div className='footerColumUnorderList'>
                                        Software developpement; Artificial Intelligence <br/> and Robotics
                                    </div>

                                </div>

                                <div className="footerColum">
                                    <div className="footerColumTitle ">
                                        <h3>Address</h3>
                                    </div>
                                    <div className='footerColumUnorderList'>
                                        <li className="list-item">
                                            <a className=""  href="#"> Ouagadougou, Burkina Faso</a>
                                        </li>
                                        <li className="list-item">
                                            <a className="" href="#" > (+226) 01 57 99 89 </a> 
                                        </li>
                                        <li className="list-item">
                                            <a className="" href="#" > contact@bonkoungoutech.com</a>
                                        </li>
                                    </div>

                                </div>

                                <div className="footerColum">
                                    <div className="footerColumTitle ">
                                        <div className='bandroidFooter'>
                                            <h3>Links</h3>
                                        </div>
                                    </div>
                                    <div className='footerColumUnorderList useFullLinks'>
                                        <li className="list-item">
                                            <a className="link" href="#">Home</a>
                                        </li>
                                        <li className='list-item'>
                                            <a className="link" href="#ourServices">Our services</a>
                                        </li>
                                        <li className="list-item">
                                            <a className="link" href="#contactUs">Contat us</a>
                                        </li>
                                        <li className="list-item">
                                            <a className="link" href="#aboutUs">About us</a>
                                        </li>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='social'>
                        <i className='fab fa-facebook-f Socialicon'></i>
                        <i className='fab fa-instagram Socialicon'></i>
                        <i className='fab fa-twitter Socialicon'></i>
                        <i className='fab fa-youtube Socialicon'></i>
                    </div>

                    <hr width="100%" color="whithe" />

                    <div className=" brandCopyRight">
                          <div> Copyright &copy;2024 Bonkoungou Technologies.</div>
                    </div>

                </div>

            </section>

            
        
        </>


    );
}

export default FooterDefaultFunct;